<template>
  <div>
    <BCard>
      <div class="d-flex align-items-start">
        <b-button
          variant="primary"
          size="sm"
          class="mr-1 rounded-lg p-0"
          tag="router-link"
          :to="{ name: 'rekening-bank' }"
        >
          <feather-icon
            size="2x"
            icon="ChevronLeftIcon"
          />
        </b-button>
        <div class="text-black font-semibold text-2xl">
          Tambah Rekening Bank
        </div>
      </div>
      <div class="mt-2">
        <div class="text-black font-semibold text-xl mb-1">
          Rekening Bank
        </div>
        <BForm @submit.prevent="sendOtp">
          <BFormGroup
            label-cols-sm="4"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="8"
          >
            <template #label>
              <span class="text-black">Nama Bank</span> <span class="text-danger">*</span>
            </template>
            <VSelect
              v-model="bankName"
              :reduce="option => option.code"
              label="name"
              :options="banks"
              :filterable="true"
              placeholder="Ketik untuk mencari..."
              :clearable="false"
              @input="rekeningNumber = ''"
            />
            <small
              v-if="!bankName && bankError"
              class="text-danger"
            >* Pilih Bank dulu ya</small>
          </BFormGroup>
          <BFormGroup
            label-cols-sm="4"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="8"
          >
            <template #label>
              <span class="text-black">No Rekening</span> <span class="text-danger">*</span>
            </template>
            <div class="d-flex gap-3">
              <BFormInput
                v-model="rekeningNumber"
                placeholder="Masukkan Nomor Rekening"
                :formatter="max20char"
                @input="rekeningError = ''"
              />
              <BButton
                variant="primary"
                class="w-[30%]"
                :disabled="disabledCheckNow"
                @click="cekRekening()"
              >
                <span v-if="!checkBankLoading">Cek Sekarang</span>
                <BSpinner
                  v-else
                  small
                  variant="light"
                />
              </BButton>
            </div>
            <small
              v-if="rekeningError !== ''"
              class="text-danger"
            >* {{ rekeningError }}</small>
          </BFormGroup>
          <BFormGroup
            v-if="accountFound || accountNotFound"
            label-cols-sm="4"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="6"
          >
            <div
              v-if="accountFound"
              class="bg-[#DCF3EB] px-[14px] py-[8px] d-flex rounded-[8px]"
            >
              <b-img
                rounded="circle"
                class="bg-[#34A770] mr-1"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/Checklist.svg"
              />
              <span class="align-self-center text-black message-account-name">Nomor Rekening berhasil ditemukan</span>
            </div>
            <div
              v-if="accountNotFound"
              class="bg-[#FFF2E2] px-[14px] py-[8px] d-flex rounded-[8px]"
            >
              <b-img
                class="bg-[#FFF2E2] mr-1"
                src="https://storage.googleapis.com/komerce/assets/icons/danger-yellow.svg"
              />
              <span class="align-self-center text-black message-account-name">Nomor Rekening salah/tidak ditemukan</span>
            </div>
          </BFormGroup>
          <BFormGroup
            label-cols-sm="4"
            label-cols-lg="2"
            content-cols-sm
            content-cols-lg="8"
          >
            <template #label>
              <span class="text-black">Nama</span> <span class="text-danger">*</span>
            </template>
            <BFormInput
              v-model="bankAccount.account_name"
              placeholder="Nama akan otomatis muncul"
              readonly
            />
          </BFormGroup>
          <div class="d-flex justify-end gap-3 mt-2">
            <BButton
              variant="outline-primary"
              @click="$router.go(-1)"
            >
              Batal
            </BButton>
            <BButton
              v-BModal="'modal-verification'"
              :variant="disabledSaveButton() ? 'secondary' : 'primary'"
              size="md"
              :disabled="disabledSaveButton()"
              type="submit"
            >
              Simpan
            </BButton>
          </div>
        </BForm>
      </div>
    </BCard>
    <ModalVerification
      :send-otp="sendOtp"
      :count-otp="countOtp"
      :expired-otp="expiredOtp"
      :source="source"
      :rekening-number="rekeningNumber"
      :rekening-name="bankAccount.account_name"
      :bank-name="bankName"
      :loading="loading"
    />
  </div>
</template>

<script>
import {
  BCard, BButton, BForm, BFormGroup, BFormInput, BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import VSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import provider from '@/provider/provider'
import { SMS_OTP, CHECK_BANK_ACCOUNT } from '@/provider/url'
import { isAlphabet } from '@/libs/helpers'
import axiosIns from '@/libs/axios'
import ModalVerification from './ModalVerification.vue'

export default {
  components: {
    VSelect,
    ModalVerification,
  },
  data() {
    return {
      loading: false,
      checkBankLoading: false,
      required,
      banks: [],
      bankName: '',
      bankError: false,
      rekeningError: '',
      bankAccount: {},
      accountFound: false,
      accountNotFound: false,
      disabledCheckNow: false,
      countOtp: 0,
      expiredOtp: 0,
      rekeningNumber: '',
      phoneNumber: this?.$store?.state?.auth?.userData?.mitras?.phone_number,
      source: 'add',
      isAlphabet,
    }
  },
  watch: {
    rekeningNumber() {
      this.accountFound = false
      this.accountNotFound = false
      this.disabledCheckNow = false
      this.rekeningError = ''
      this.bankAccount = {}
    },
  },
  mounted() {
    this.getDataBank()
  },
  methods: {
    async getDataBank() {
      const url = 'xendit/disbursementbankAvailable'
      await this.$http.get(url)
        .then(res => {
          const { data } = res.data
          this.banks = data
        })
    },
    async cekRekening() {
      if (this.bankName === '') {
        this.bankError = true
      } else if (this.rekeningNumber === '') {
        this.rekeningError = 'Nomor rekening tidak boleh kosong'
      } else if (this.rekeningNumber.length < 6) {
        this.rekeningError = 'Nomor rekening tidak boleh kurang dari 6 digit'
      } else {
        this.checkBankLoading = true
        this.disabledCheckNow = true
        await axiosIns.post(`${CHECK_BANK_ACCOUNT}`, {
          bank_name: this.bankName,
          account_no: this.rekeningNumber,
        })
          .then(res => {
            const { data } = res.data
            if (Object.keys(data).length === 0) {
              this.bankAccount = {}
              this.accountFound = false
              this.accountNotFound = true
            } else {
              this.bankAccount = data
              this.accountFound = true
              this.accountNotFound = false
            }
            this.checkBankLoading = false
          }).catch(() => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Gagal',
                icon: 'AlertCircleIcon',
                text: 'Gagal cek rekening, silahkan coba lagi',
                variant: 'danger',
              },
            }, { timeout: 2000 })
            this.checkBankLoading = false
          })
      }
    },
    async sendOtp() {
      this.loading = true
      const datas = new FormData()
      const dataBody = [
        {
          name: 'phone_number',
          value: this.phoneNumber,
        },
        {
          name: 'session',
          value: 'rekening',
        },
      ]
      dataBody.map(item => datas.append(item.name, item.value))

      await provider
        .insert({
          url: SMS_OTP,
          body: datas,
        })
        .then(res => {
          const { data } = res
          this.countOtp = data.expired_at
          this.expiredOtp = data.check_request_otp
          this.loading = false
          this.countDownTimerOtp()
        })
        .catch(() => {
          this.loading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'gagal',
              icon: 'AlertCircleIcon',
              text: 'Gagal kirim OTP, silahkan coba lagi',
              variant: 'danger',
            },
          }, 2000)
        })
    },
    changeRekeningNumber(number) {
      this.rekeningNumber = number
    },
    countDownTimerOtp() {
      if (this.countOtp > 0) {
        setTimeout(() => {
          this.countOtp -= 1
          this.countDownTimerOtp()
        }, 1000)
      }
    },
    max20char(value) {
      const cleared = value.replace(/\D/g, '')
      if (cleared.length > 20) {
        return cleared.substring(0, 20)
      }
      return cleared
    },
    disabledSaveButton() {
      if (Object.keys(this.bankAccount).length === 0) return true
      return false
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
@import './Rekening.scss'
</style>
